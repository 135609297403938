import { EStatus, Prospect } from 'types/common';
import { Request } from '../request';

export const getProspectList = () =>
    Request.post({ url: '/schedule-server/prospect/list' });

export const createProspect = (item: Partial<Prospect>) =>
    Request.post({ url: '/schedule-server/prospect/create', params: item });

type TSendEmailToProspect = {
    content: string,
    title: string,
    to: string,
};
export const sendEmailToProspect = (params: TSendEmailToProspect) =>
    Request.post({ url: '/schedule-server/prospect/email/reply', params });

export const getProspectNoteList = (prospectId: number) =>
    Request.post({ url: '/schedule-server/prospectNotes/listByProspectId', params: { prospectId } });

export const createProspectNote = (prospectId: number, notes: string) =>
    Request.post({ url: '/schedule-server/prospectNotes/create', params: { prospectId, notes } });

export const getProspectDetail = (prospectId: number) =>
    Request.get({ url: `/schedule-server/prospect/get/${prospectId}` });

export const updateProspect = (prospectId: number, item: Partial<Prospect>) =>
    Request.put({ url: `/schedule-server/prospect/update/${prospectId}`, params: item });

export const getProspectAppointList = (prospectId: number) =>
    Request.get({ url: `/schedule-server/prospect/listAppointByProspectId/${prospectId}`, params: { prospectId } });

export const updateProspectEhrByIds = (prospectIds: number[], ehr: 'Yes' | 'No') =>
    Request.put({ url: '/schedule-server/prospect/updateEhr', params: { ids: prospectIds, ehr } });

export const updateProspectStatusByIds = (prospectIds: number[], status: EStatus) =>
    Request.put({ url: '/schedule-server/prospect/updateStatus', params: { ids: prospectIds, status } });

export const getProspectEmaiListByIds = (ids: string[]) =>
    Request.post({ url: '/schedule-server/prospect/email/listByIds', params: { ids } });

export const getProspectEmailIdList = (email: string) =>
    Request.post({ url: '/schedule-server/prospect/email/listId', params: { prospectEmail: email } });

export const getProspectEmailList = (email: string, prospectId: number) =>
    Request.post({ url: '/schedule-server/prospect/email/list', params: { prospectEmail: email, prospectId } });

export const batchCreateProspect = (data: Partial<Prospect>[]) => Request.post({ url: 'schedule-server/prospect/create/patient/batch', params: data });

export const getProspectSettingInfo = () =>
    Request.get({ url: `/schedule-server/provider/prospect/setting/info` });

export const getProviderNotificationConfig = () =>
    Request.get({ url: `/schedule-server/provider/getProviderNotificationConfig` });


export const saveProspectSetting = (avaToneName: string | null, avaAutoReply: boolean | null, smsNotificationEnabled?: boolean) =>
    Request.post({
        url: `/schedule-server/provider/prospect/setting/save`,
        params: { avaToneName, avaAutoReply, smsNotificationEnabled: null }
    });

export const saveProspectSettingSms = (smsNotificationEnabled?: boolean) =>
    Request.post({
        url: `/schedule-server/provider/prospect/setting/save`,
        params: { smsNotificationEnabled, avaToneName: null, avaAutoReply: null }
    });

export const feedbackAutoReply = (emailId: string, like: boolean) =>
    Request.post(
        {
            url:
                '/schedule-server/prospect/email/feedback',
            params: { emailId, like }
        },
    );
