import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Checkbox } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import s from './s.module.less';

interface Option {
    label: string;
    value: string;
}

interface CheckboxSelectProps {
    options: Option[];
    value?: string[];
    onChange?: (values: string[]) => void;
    allCheckedText?: string;
    placeholder?: string;
    style?: React.CSSProperties;
    className?: string;
    dropdownStyle?: React.CSSProperties;
    dropdownClassName?: string;
}

const ArrowIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="down"
        width="1em"
        height="1em"
        fill="#bfbfbf"
        aria-hidden="true"
    >
        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
    </svg>
);

const CheckboxSelect: React.FC<CheckboxSelectProps> = ({
    options,
    value,
    onChange,
    allCheckedText = 'All collection methods',
    placeholder = 'Select items',
    style,
    className,
    dropdownStyle,
    dropdownClassName,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<string[]>(value || []);
    const selectRef = useRef<HTMLDivElement>(null);

    const isAllSelected = useMemo(() => {
        return selectedValues.length === options.length;
    }, [selectedValues, options]);

    const displayText = useMemo(() => {
        if (selectedValues.length === 0) {
            return <span className={s.placeholder}>{placeholder}</span>;
        }
        if (isAllSelected) return allCheckedText;
        if (selectedValues.length === 1) {
            return options.find((opt) => opt.value === selectedValues[0])?.label;
        }
        return `${selectedValues.length} items selected`;
    }, [selectedValues, isAllSelected, options, allCheckedText, placeholder]);

    const handleSelect = (value: string, checked: boolean) => {
        let newValues: string[];
        newValues = checked
            ? [...selectedValues, value]
            : selectedValues.filter((v) => v !== value);
        setSelectedValues(newValues);
        onChange?.(newValues);
    };

    // 处理点击外部关闭下拉框
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`${s.selectContainer} ${className || ''}`}
            ref={selectRef}
            style={style}
        >
            <div
                className={s.selectTrigger}
                onClick={() => setIsOpen(!isOpen)}
                role="button"
                tabIndex={0}
            >
                <span className={s.displayText}>{displayText}</span>
                <span className={`${s.arrow} ${isOpen ? s.open : ''}`}>
                    <ArrowIcon />
                </span>
            </div>
            {isOpen && (
                <div
                    className={`${s.dropdown} ${dropdownClassName || ''}`}
                    style={dropdownStyle}
                >
                    {options.map((option) => (
                        <div key={option.value} className={s.option}>
                            <Checkbox
                                checked={selectedValues.includes(option.value)}
                                onChange={(e) => handleSelect(option.value, e.target.checked)}
                            >
                                {option.label}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CheckboxSelect;
