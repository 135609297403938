import { Request } from '../request';
import type { TMedicationSetting, TServiceSetting } from 'types/helloKlarity';
import { TMedication } from 'types/common';
import type { TProviderSortItem } from 'types/providerSort';

export const getKlarityServiceList = (
    pageId: number | string,
) => Request.get({
    url: '/admin-server/klarity/page/service/getByPageId/',
    params: {
        pageId,
    },
});

export const getKlarityServiceDetail = (
    id: number,
) => Request.get({ url: `/admin-server/klarity/page/service/getById/${id}` });

export const klarityServiceUpdate = (data: TServiceSetting) => Request.post({ url: '/admin-server/klarity/page/service/saveOrUpdate', params: data });

export const exchangePriority = (data: {
    fromSpecialty: string;
    toSpecialty: string;
}) => Request.put({ url: '/admin-server/klarity/condition/specialty/priority/exchange', params: data });

export const conditionSaveOrUpdateBatch = (data: TServiceSetting[]) => Request.post({ url: '/admin-server/klarity/condition/saveOrUpdate/batch', params: data });

export const getMedicationList = () => Request.get({
    url: '/admin-server/klarity/page/medication/getListAll',
});

export const klarityMedicationUpdate = (data: TMedicationSetting) => Request.post({ url: '/admin-server/klarity/page/medication/saveOrUpdate', params: data });

export const getKlarityProviderList = (
    pageId: string,
) => Request.get({ url: `/admin-server/klarity/page/provider/list/${pageId}` });

export const saveKlarityProviderList = (pageId: string, data: TProviderSortItem[]) => Request.post({ url: `/admin-server/klarity/page/provider/ranking/save/${pageId}`, params: data });

export const resetKlarityProviderList = (pageId: string) => Request.post({ url: `/admin-server/klarity/page/provider/ranking/reset/${pageId}` });
